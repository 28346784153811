import { isNull } from 'lodash'
import * as types from './mutation-types'
import { getBaseUrl, getBaseUrlNewApi } from '~/config/api'
import { setLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

export default {
  async updateUser({ dispatch }) {
    const user = await dispatch('fetchUser')
    dispatch('setUser', user?.data?.data ?? {})
  },
  async fetchUser({ commit }) {
    try {
      return await this.$axios.get(
        `${getBaseUrl(process.env.NODE_ENV)}/api/auth/user`
      )
    } catch (e) {
      console.error('Fetch user: ', e)
    }
  },
  setUser({ commit, state, getters }, user = null) {
    const refreshToken = this.$auth.strategy.refreshToken.get() ?? null
    if (!isNull(user) && refreshToken) {
      user.refreshToken = refreshToken
    }
    commit(`user/${types.SET_USER}`, user, { root: true })
    if (isNull(user)) return false
    setLocalStorage({
      name: 'user',
      data: user
    })
  },
  async fetchAdmins({ commit, getters }) {
    try {
      if (getters.admins.length) return

      const res = await this.$axios.get(`${getBaseUrlNewApi()}/users/admins`)

      commit(`setAdmins`, res.data)
    } catch (e) {
      console.error('SetPracticeLocationsLight: ', e)
    }

  }
}
