import { isObjectNotUndefinedHasProps } from './object'

export const removeLocalStorageItem = (key, session = false) => {
  return session ? sessionStorage.removeItem(key) : localStorage.removeItem(key)
}
export const setLocalStorage = (e, session = false) => {
  return session ? sessionStorage.setItem(e.name, JSON.stringify(e.data)) : localStorage.setItem(e.name, JSON.stringify(e.data))
}
export const getLocalStorage = (alias, session = false) => {
  if (alias.length) {
    return JSON.parse(session ? sessionStorage.getItem(alias) : localStorage.getItem(alias))
  } else {
    return false
  }
}

export const clearLocalStorage = () => {
  localStorage.clear()
}

export const removeLocalStorageProp = (alias = '', propName = '') => {
  if (!alias || !propName) return false
  const data = getLocalStorage(alias)
  if (!data || !isObjectNotUndefinedHasProps(data)) return false
  // eslint-disable-next-line no-prototype-builtins
  if (!data.hasOwnProperty(propName)) return false
  delete data[propName]
  setLocalStorage({
    name: alias,
    data,
  })
}

export const getLocalStorageProp = (alias = '', propName = '', session = false) => {
  if (!alias || !propName) return null
  const data = getLocalStorage(alias, session)
  if (!data || !isObjectNotUndefinedHasProps(data)) return null
  return data?.[propName] ?? null
}

export const saveLocalStorageProp = (
  alias = '',
  propName = '',
  payload = null,
  session = false,
) => {
  if (!alias || !propName) return false
  const data = getLocalStorage(alias, session) ?? {}
  setLocalStorage({
    name: alias,
    data: {
      ...data,
      [propName]: payload,
    },
    session
  })
  return true
}
