import * as types from './mutation-types'
import { getBaseUrlNewApi } from '~/config/api'

export default {
  async FetchDistributorsLight() {
    try {
      return (await this.$axios.get(`${getBaseUrlNewApi()}/distributor/get-options`)).data
    } catch (e) {
      console.error('FetchDistributorsLight: ', e)
    }
  },
  async FetchPracticeLocationsLight() {
    try {
      return (await this.$axios.get(`${getBaseUrlNewApi()}/practice-location/get-practice-location-options?with_buying_group=1`)).data
    } catch (e) {
      console.error('FetchPracticeLocationsLight: ', e)
    }
  },
  async SetDistributorsLight({ commit, dispatch, getters }) {
    try {
      if (getters.distributorsLight.length) return

      commit(
        types.SET_DISTRIBUTORS_LIGHT,
        await dispatch('FetchDistributorsLight')
      )
    } catch (e) {
      console.error('SetDistributorsLight: ', e)
    }
  },
  async SetPracticeLocationsLight({ commit, dispatch, getters }) {
    try {
      if (getters.practiceLocationsLight.length) return

      commit(
        types.SET_PRACTICE_LOCATIONS__LIGHT,
        await dispatch('FetchPracticeLocationsLight')
      )
    } catch (e) {
      console.error('SetPracticeLocationsLight: ', e)
    }
  }
}
